/* Variables go in here for global usage within all .sass files.
 * To do that we have to add 
 *
 * @import '_variables.sass'
 *
 * In every .sass file in which we want to use the variables.
 *
 * Variables have the form $variable: value */
/* Variables go in here for global usage within all .sass files.
 * To do that we have to add 
 *
 * @import '_variables.sass'
 *
 * In every .sass file in which we want to use the variables.
 *
 * Variables have the form $variable: value */
/* Variables go in here for global usage within all .sass files.
 * To do that we have to add 
 *
 * @import '_variables.sass'
 *
 * In every .sass file in which we want to use the variables.
 *
 * Variables have the form $variable: value */
/* Mixins */
body {
  margin: 0;
  padding: 0; }

#flex-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4em; }
  @media (max-width: 640px) {
    #flex-container {
      padding: 1em; } }

#loading-spinner {
  color: #1890ff;
  font-size: 4em;
  position: relative;
  top: -3em; }

.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%; }

.flex-column {
  display: flex;
  flex-direction: column; }

.header-text {
  font-weight: bold;
  color: #ffffff; }
  @media (max-width: 1280px) {
    .header-text {
      font-size: small; } }
  @media (min-width: 1600px) {
    .header-text {
      font-size: large; } }

#logo {
  max-width: 100%;
  margin-bottom: 30px; }

#center-text {
  text-align: center; }

#background {
  position: fixed; }

.subtitle {
  color: #252525;
  margin-top: 10px;
  text-align: center;
  font-weight: bold; }
  @media screen and (min-width: 1280px) {
    .subtitle {
      font-size: small; } }
  @media screen and (min-width: 1600px) {
    .subtitle {
      font-size: large; } }

.subtitle red {
  color: rgba(243, 57, 0, 0.842); }

.subtitle blue {
  color: #1D9AF2; }

.header-img {
  padding: 0 15px;
  transition: all 0.2s ease-in-out;
  width: 100%;
  margin-top: 10px;
  scale: 0.85; }
  .header-img:hover {
    transform: scale(1.1); }
