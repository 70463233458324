/* Variables go in here for global usage within all .sass files.
 * To do that we have to add 
 *
 * @import '_variables.sass'
 *
 * In every .sass file in which we want to use the variables.
 *
 * Variables have the form $variable: value */
/* Variables go in here for global usage within all .sass files.
 * To do that we have to add 
 *
 * @import '_variables.sass'
 *
 * In every .sass file in which we want to use the variables.
 *
 * Variables have the form $variable: value */
/* Mixins */
/* Variables go in here for global usage within all .sass files.
 * To do that we have to add 
 *
 * @import '_variables.sass'
 *
 * In every .sass file in which we want to use the variables.
 *
 * Variables have the form $variable: value */
/* Variables go in here for global usage within all .sass files.
 * To do that we have to add 
 *
 * @import '_variables.sass'
 *
 * In every .sass file in which we want to use the variables.
 *
 * Variables have the form $variable: value */
/* Mixins */
body {
  margin: 0;
  padding: 0; }

#flex-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4em; }
  @media (max-width: 640px) {
    #flex-container {
      padding: 1em; } }

#loading-spinner {
  color: #1890ff;
  font-size: 4em;
  position: relative;
  top: -3em; }

.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%; }

.flex-column {
  display: flex;
  flex-direction: column; }

.master-flex-row-create-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-evenly; }

.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%; }

.flex-column-margin-top {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  align-items: center;
  justify-content: center; }

.space {
  height: 40px; }
