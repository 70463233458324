@import '_variables.scss';
@import '_mixins.scss';

body{
    margin: 0;
    padding: 0;
}

#flex-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 4em;

    @include sm{
        padding: 1em;
    }
}

#loading-spinner{
    color: $main-blue;
    font-size: 4em;
    position: relative;
    top: -3em;
}

.flex-row{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.flex-column{
    display: flex;
    flex-direction: column;
}