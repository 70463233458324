/* Variables go in here for global usage within all .sass files.
 * To do that we have to add 
 *
 * @import '_variables.sass'
 *
 * In every .sass file in which we want to use the variables.
 *
 * Variables have the form $variable: value */
/* Variables go in here for global usage within all .sass files.
 * To do that we have to add 
 *
 * @import '_variables.sass'
 *
 * In every .sass file in which we want to use the variables.
 *
 * Variables have the form $variable: value */
/* Mixins */
/* Variables go in here for global usage within all .sass files.
 * To do that we have to add 
 *
 * @import '_variables.sass'
 *
 * In every .sass file in which we want to use the variables.
 *
 * Variables have the form $variable: value */
/* Variables go in here for global usage within all .sass files.
 * To do that we have to add 
 *
 * @import '_variables.sass'
 *
 * In every .sass file in which we want to use the variables.
 *
 * Variables have the form $variable: value */
/* Mixins */
body {
  margin: 0;
  padding: 0; }

#flex-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4em; }
  @media (max-width: 640px) {
    #flex-container {
      padding: 1em; } }

#loading-spinner {
  color: #1890ff;
  font-size: 4em;
  position: relative;
  top: -3em; }

.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%; }

.flex-column {
  display: flex;
  flex-direction: column; }

.container {
  overflow: auto;
  background-color: #ffffff; }
  @media (max-width: 640px) {
    .container {
      margin: 10px;
      width: 100vw;
      height: 95vh; } }
  @media (min-width: 768px) {
    .container {
      margin: 15px;
      width: 100vw;
      height: 95vh; } }
  @media (min-width: 992px) {
    .container {
      margin: 20px;
      width: 100vw;
      height: 92vh; } }
  @media (min-width: 1200px) {
    .container {
      margin: 30px;
      width: 100vw;
      height: 92vh; } }

.master-flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  overflow-x: auto; }

.flex-row-margin {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 14px; }

.flex-column-margin {
  display: flex;
  flex-direction: column;
  margin: 14px; }

.hr1 {
  border-top: 1px solid black;
  width: 100%;
  opacity: 0.1;
  margin-top: 20px; }

.hr2 {
  border-top: 1px solid black;
  width: 100%;
  opacity: 0.1; }

.scroll-list {
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden; }

.scroll-list2 {
  max-height: 35vh;
  overflow-y: auto;
  overflow-x: hidden; }

.under-title-container {
  justify-content: space-between;
  display: flex;
  flex-direction: row; }

.box-container {
  box-shadow: 2px 2px 20px #e6e3e3; }

.header-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  text-align: center; }

.timeline-container {
  box-shadow: 2px 2px 20px #e6e3e3;
  margin-left: 10px; }

.title-text {
  display: flex;
  color: #383838;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 23px;
  font-weight: bold; }

.project-hyperlink {
  text-decoration: underline; }

.header {
  color: #383838;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
  margin: 10px;
  margin-top: 15px;
  font-weight: bold;
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */ }

.file-name {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 65%;
  margin-left: 15px; }

.unselectable-text {
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */ }

.scrollable-timeline {
  justify-content: center;
  overflow: auto;
  max-height: 50vh; }

.vertical-line {
  display: flex;
  margin-right: 10px;
  border-left: 3px solid #39aaff; }

a.movin {
  transition: 0.25s ease; }

a.movin:hover {
  background-color: #dff1ff;
  border-radius: 7px;
  padding: 5px;
  transition: 0.25s ease; }

a.movin:active {
  background-color: #93d0ff;
  border-radius: 7px; }

.glow-btn {
  -webkit-animation: glowing 1500ms infinite;
  -moz-animation: glowing 1500ms infinite;
  -o-animation: glowing 1500ms infinite;
  animation: glowing 1500ms infinite; }

@-webkit-keyframes glowing {
  0% {
    background-color: #2981e6;
    -webkit-box-shadow: 0 0 3px #2981e6; }
  50% {
    background-color: #2981e6;
    -webkit-box-shadow: 0 0 30px #2981e6; }
  100% {
    background-color: #2981e6;
    -webkit-box-shadow: 0 0 3px #2981e6; } }

@-moz-keyframes glowing {
  0% {
    background-color: #2981e6;
    -moz-box-shadow: 0 0 3px #2981e6; }
  50% {
    background-color: #2981e6;
    -moz-box-shadow: 0 0 30px #2981e6; }
  100% {
    background-color: #2981e6;
    -moz-box-shadow: 0 0 3px #2981e6; } }

@-o-keyframes glowing {
  0% {
    background-color: #2981e6;
    box-shadow: 0 0 3px #2981e6; }
  50% {
    background-color: #2981e6;
    box-shadow: 0 0 30px #2981e6; }
  100% {
    background-color: #2981e6;
    box-shadow: 0 0 3px #2981e6; } }

@keyframes glowing {
  0% {
    background-color: #2981e6;
    box-shadow: 0 0 3px #2981e6; }
  50% {
    background-color: #2981e6;
    box-shadow: 0 0 30px #2981e6; }
  100% {
    background-color: #2981e6;
    box-shadow: 0 0 3px #2981e6; } }
