/* Variables go in here for global usage within all .sass files.
 * To do that we have to add 
 *
 * @import '_variables.sass'
 *
 * In every .sass file in which we want to use the variables.
 *
 * Variables have the form $variable: value */
/* Variables go in here for global usage within all .sass files.
 * To do that we have to add 
 *
 * @import '_variables.sass'
 *
 * In every .sass file in which we want to use the variables.
 *
 * Variables have the form $variable: value */
/* Variables go in here for global usage within all .sass files.
 * To do that we have to add 
 *
 * @import '_variables.sass'
 *
 * In every .sass file in which we want to use the variables.
 *
 * Variables have the form $variable: value */
/* Mixins */
body {
  margin: 0;
  padding: 0; }

#flex-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4em; }
  @media (max-width: 640px) {
    #flex-container {
      padding: 1em; } }

#loading-spinner {
  color: #1890ff;
  font-size: 4em;
  position: relative;
  top: -3em; }

.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%; }

.flex-column {
  display: flex;
  flex-direction: column; }

#form {
  /* max-width instead of width so that the browser can
     * shorten the width of the fields as it sees fit.
     * This ensures more responsiveness */
  max-width: 30em;
  min-width: 15em; }
