@import '_variables.scss';

/* Mixins */
@mixin sm{
    @media (max-width: #{$screen-sm}){
        @content;
    }
}

@mixin md{
    @media (min-width: #{$screen-md}){
        @content;
    }
}

@mixin lg{
    @media (min-width: #{$screen-lg}){
        @content;
    }
}

@mixin xl{
    @media (min-width: #{$screen-xl}){
        @content;
    }
}

@mixin unselectable-text {
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
  }